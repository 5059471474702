import { TimeEntryTag } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const deleteTimeEntryTag = async (
  supabase: SupabaseClient,
  timeEntryTagId: string
): Promise<void> => {
  const { error } = await supabase
    .from("time_entry_tags")
    .delete()
    .eq("time_entry_id", timeEntryTagId);
  if (error) {
    console.error("Error deleting time entry tag:", error);
    throw error;
  }
};

export const createTimeEntryTags = async (
  supabase: SupabaseClient,
  timeEntryTags: TimeEntryTag[]
): Promise<void> => {
  const { error } = await supabase
    .from("time_entry_tags")
    .insert(timeEntryTags);
  if (error) {
    console.error("Error creating time entry tags:", error);
    throw error;
  }
};
