import { Tag } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const fetchTags = async (
  supabase: SupabaseClient,
  userId: string
): Promise<Tag[]> => {
  const { data, error } = await supabase
    .from("tags")
    .select("*")
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }

  return data || [];
};

export const createTag = async (
  supabase: SupabaseClient,
  tagName: string,
  userId: string
): Promise<Tag> => {
  console.log("Creating tag:", tagName);
  console.log("User ID:", userId);
  const { data, error } = await supabase
    .from("tags")
    .insert({ name: tagName, user_id: userId })
    .select()
    .single();

  if (error) {
    console.error("Error creating tag:", error);
    throw error;
  }
  console.log("Created tag:", data);
  return data;
};

export const getTagForName = async (
  supabase: SupabaseClient,
  userId: string,
  tagName: string
): Promise<Tag> => {
  const { data, error } = await supabase
    .from("tags")
    .select("*")
    .eq("name", tagName)
    .eq("user_id", userId)
    .single();

  if (error) {
    console.error("Error fetching tag:", error);
    throw error;
  }
  return data;
};

export const createTagIfNotExists = async (
  supabase: SupabaseClient,
  userId: string,
  tagName: string
): Promise<Tag> => {
  try {
    const tag = await getTagForName(supabase, userId, tagName);
    return tag;
  } catch (error) {
    // console.error("Error fetching tag:", error);
    return createTag(supabase, tagName, userId);
  }
};
