// import { Link } from "@tanstack/react-router";
// import logo from "/src/assets/logo.svg";

// export function Footer() {
//   return (
//     <footer className="footer p-10 bg-neutral text-primary">
//       <div>
//         <Link to="/" className="flex items-center space-x-2">
//           <img src={logo} alt="cronloom Logo" className="h-8 w-8" />
//           <span className="font-bold text-xl">cronloom</span>
//         </Link>
//         <p>Empowering your time management since 2024</p>
//       </div>
//       <div>
//         <span className="footer-title">Services</span>
//         <Link to="/" className="link link-hover">
//           Home
//         </Link>
//         <Link to="/clients-projects" className="link link-hover">
//           Clients & Projects
//         </Link>
//         <Link to="/reports" className="link link-hover">
//           Reports
//         </Link>
//         <Link to="/settings" className="link link-hover">
//           Settings
//         </Link>
//       </div>
//       <div>
//         <span className="footer-title">Company</span>
//         <a className="link link-hover">About us</a>
//         <a className="link link-hover">Contact</a>
//         <a className="link link-hover">Jobs</a>
//         <a className="link link-hover">Press kit</a>
//       </div>
//       <div>
//         <span className="footer-title">Legal</span>
//         <a className="link link-hover">Terms of use</a>
//         <a className="link link-hover">Privacy policy</a>
//         <a className="link link-hover">Cookie policy</a>
//       </div>
//     </footer>
//   );
// }

import { Logo } from "./Logo";
// import { useNavigate } from "react-router-dom";

const footerSections = {
  product: {
    title: "Product",
    links: [
      { href: "https://cronloom.io", label: "Home" },
      { href: "https://cronloom.io/#features", label: "Features" },
      { href: "https://cronloom.io/#pricing", label: "Pricing" },
    ],
  },
  account: {
    title: "Account",
    links: [
      { href: "https://app.cronloom.io/signin", label: "Sign In" },
      { href: "https://app.cronloom.io/signup", label: "Sign Up" },
    ],
  },
  legal: {
    title: "Legal",
    links: [
      {
        href: "https://cronloom.io/legal-notice",
        label: "Legal Notice / Impressum",
      },
      {
        href: "https://cronloom.io/privacy-policy",
        label: "Privacy Policy",
      },
      {
        href: "https://cronloom.io/terms-of-service",
        label: "Terms of Service",
      },
      {
        href: "https://cronloom.io/refund-policy",
        label: "Refund Policy",
      },
    ],
  },
};

export function Footer() {
  const currentYear = new Date().getFullYear();
  // const navigate = useNavigate();

  // const handleLinkClick = (event, href) => {
  //   event.preventDefault();

  //   if (href.startsWith("/#")) {
  //     const targetId = href.split("#")[1];
  //     navigate(href, { replace: true });
  //     document.getElementById(targetId)?.scrollIntoView({ behavior: "smooth" });
  //   } else {
  //     navigate(href);
  //   }
  // };

  return (
    <footer className="bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1">
            <Logo />
            <p className="mt-4 text-gray-500 dark:text-gray-400 text-sm">
              Making time tracking simple and effective for teams and
              freelancers.
            </p>
          </div>

          {Object.entries(footerSections).map(([key, section]) => (
            <div key={key}>
              <h3 className="text-sm font-semibold text-gray-900 dark:text-white tracking-wider uppercase">
                {section.title}
              </h3>
              <ul className="mt-4 space-y-4">
                {section.links.map((link) => (
                  <li key={link.label}>
                    <a
                      href={link.href}
                      // onClick={(e) => handleLinkClick(e, link.href)}
                      className="text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-8">
          <p className="text-center text-gray-400 dark:text-gray-500 text-sm">
            © {currentYear} cronloom.io. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
