import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { CheckCircle } from "lucide-react";

export default function CheckoutSuccess() {
  return (
    <div className="flex items-center justify-center p-4">
      <Card className="w-full max-w-md">
        <CardHeader className="text-center">
          <div className="mb-4 flex justify-center">
            <CheckCircle className="h-16 w-16 text-green-500" />
          </div>
          <CardTitle className="text-2xl font-bold">
            Checkout Successful
          </CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-muted-foreground">
            Happy to see you here 👋! Please sign up with the email you provided
            during checkout get started!
          </p>
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button
            onClick={() => (window.location.href = "/signup")}
            className="mt-4"
          >
            Go to Sign Up
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
