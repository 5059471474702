import React, { useState } from "react";
import { UserPreferences } from "@/models";

interface OnboardingDialogProps {
  isOpen: boolean;
  onClose: (preferences: UserPreferences | null) => void;
}

export function OnboardingDialog({ isOpen, onClose }: OnboardingDialogProps) {
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [language, setLanguage] = useState("en");
  const [defaultHourlyRate, setDefaultHourlyRate] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const preferences: UserPreferences = {
      user_id: "", // This will be set in the parent component
      name,
      currency,
      language,
      default_hourly_rate: parseFloat(defaultHourlyRate) || 0,
      created_at: new Date().toISOString(),
      subscription_status: "trialing",
      trial_start_date: new Date(),
    };
    onClose(preferences);
  };

  const handleClose = () => {
    onClose(null); // Indicate that the dialog was closed without saving preferences
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="modal modal-open">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Welcome to cronloom!</h3>
          <p className="py-4">
            Let's set up your account with a few quick questions.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Name (optional)</span>
              </label>
              <input
                type="text"
                placeholder="Your name"
                className="input input-bordered w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-control w-full mt-4">
              <label className="label">
                <span className="label-text">Currency</span>
              </label>
              <select
                className="select select-bordered w-full"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            </div>
            <div className="form-control w-full mt-4">
              <label className="label">
                <span className="label-text">Language</span>
              </label>
              <select
                className="select select-bordered w-full"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="de">Deutsch</option>
              </select>
            </div>
            <div className="form-control w-full mt-4">
              <label className="label">
                <span className="label-text">Default Hourly Rate</span>
              </label>
              <input
                type="number"
                placeholder="0.00"
                className="input input-bordered w-full"
                value={defaultHourlyRate}
                onChange={(e) => setDefaultHourlyRate(e.target.value)}
              />
            </div>
            <div className="modal-action">
              <button type="submit" className="btn btn-primary">
                Save preferences
              </button>
              <button type="button" className="btn" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
