import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Layout } from "../components/layout";
import { useAuth } from "@clerk/clerk-react";
import { Navigate } from "@tanstack/react-router";

const ErrorComponent = () => (
  <div>
    <h1>Error</h1>
    <p>Something went wrong.</p>
  </div>
);

export const Route = createRootRoute({
  component: () => {
    const { isSignedIn, isLoaded } = useAuth();
    const currentPath = window.location.pathname;

    if (!isLoaded) {
      const theme = localStorage.getItem("theme") || "light";
      if (theme === "dark") {
        document.documentElement.classList.add("dark");
      }
      return (
        <>
          <div className="flex items-center justify-center h-screen">
            <span className="loading loading-infinity loading-lg"></span>
          </div>
        </>
      );
    }

    if (!isSignedIn && currentPath != "/signin") {
      let destination = "/signin";
      if (currentPath == "/signup") {
        destination = "/signup";
      }
      console.log(`Navigating to ${destination}`);
      return (
        <>
          <Navigate to={destination} />
          <Layout key={window.location.pathname}>
            <Outlet />
          </Layout>
        </>
      );
    }

    return (
      <Layout key={window.location.pathname}>
        <Outlet />
      </Layout>
    );
  },
  errorComponent: ErrorComponent,
});
