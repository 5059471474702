import React, { createContext, useContext, useEffect, useState } from "react";
import { initializePaddle, Paddle } from "@paddle/paddle-js";

interface PaddleContextType {
  paddle: Paddle | undefined;
  isLoading: boolean;
}

const PaddleContext = createContext<PaddleContextType | undefined>(undefined);

export function PaddleProvider({ children }: { children: React.ReactNode }) {
  const [paddle, setPaddle] = useState<Paddle>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const paddleToken = import.meta.env.VITE_PADDLE_TOKEN;

    initializePaddle({
      environment: "sandbox",
      token: paddleToken,
      // eventCallback: (data) => {
      //   console.log("Paddle event:", data);
      //   if(!data) {
      //     return;
      //   }
      //   if(data.name === 'checkout.completed') {
      //     console.log("Checkout completed");
      //   }
      //   if(data.name === 'checkout.closed') {
      //   }
      // },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <PaddleContext.Provider value={{ paddle, isLoading }}>
      {children}
    </PaddleContext.Provider>
  );
}

export function usePaddle() {
  const context = useContext(PaddleContext);
  if (context === undefined) {
    throw new Error("usePaddle must be used within a PaddleProvider");
  }
  return context;
}
