import { Project } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const fetchProjects = async (
  supabase: SupabaseClient,
  userId: string
): Promise<Project[]> => {
  const { data, error } = await supabase
    .from("projects")
    .select("*")
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching projects:", error);
    throw error;
  }

  return data || [];
};
