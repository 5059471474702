import {
  Clock,
  BarChart3,
  FileSpreadsheet,
  DollarSign,
  Briefcase,
  Users,
  Menu,
  X,
  Moon,
  Sun,
  Check,
} from "lucide-react";
import { memo } from "react";

// Create memoized components
const MemoizedClock = memo(Clock);
const MemoizedBarChart = memo(BarChart3);
const MemoizedFileSpreadsheet = memo(FileSpreadsheet);
const MemoizedDollarSign = memo(DollarSign);
const MemoizedBriefcase = memo(Briefcase);
const MemoizedUsers = memo(Users);
const MemoizedMenu = memo(Menu);
const MemoizedX = memo(X);
const MemoizedMoon = memo(Moon);
const MemoizedSun = memo(Sun);
const MemoizedCheck = memo(Check);

// Export memoized components
export const Icons = {
  Clock: MemoizedClock,
  BarChart: MemoizedBarChart,
  FileSpreadsheet: MemoizedFileSpreadsheet,
  DollarSign: MemoizedDollarSign,
  Briefcase: MemoizedBriefcase,
  Users: MemoizedUsers,
  Menu: MemoizedMenu,
  X: MemoizedX,
  Moon: MemoizedMoon,
  Sun: MemoizedSun,
  Check: MemoizedCheck,
} as const;
