import { TimeEntry, TimeEntryWithTags } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const fetchTimeEntriesWithTags = async (
  supabase: SupabaseClient,
  userId: string
): Promise<TimeEntryWithTags[]> => {
  const { data, error } = await supabase
    .from("time_entries")
    .select(
      `
      *,
      tags:time_entry_tags(tag:tags(*))
    `
    )
    .eq("user_id", userId)
    .order("start_time", { ascending: false });

  if (error) {
    console.error("Error fetching time entries:", error);
    throw error;
  }

  return (
    data?.map((entry: any) => ({
      ...entry,
      tags: entry.tags.map((t: any) => t.tag),
    })) || []
  );
};

export const fetchTimeEntries = async (
  supabase: SupabaseClient,
  userId: string
): Promise<TimeEntryWithTags[]> => {
  const { data, error } = await supabase
    .from("time_entries")
    .select(
      `
      *,
      tags:time_entry_tags(tag:tags(*))
    `
    )
    .eq("user_id", userId)
    .order("start_time", { ascending: false });

  if (error) {
    console.error("Error fetching time entries:", error);
    throw error;
  }

  return data;
};

export const fetchTimeEntry = async (
  supabase: SupabaseClient,
  timeEntryId: string
): Promise<TimeEntryWithTags | null> => {
  const { data, error } = await supabase
    .from("time_entries")
    .select(
      `
        *,
        tags:time_entry_tags(tag:tags(*))
        `
    )
    .eq("id", timeEntryId)
    .single();

  if (error) {
    console.error("Error fetching time entry:", error);
    throw error;
  }

  return data;
};

export const createTimeEntry = async (
  supabase: SupabaseClient,
  timeEntry: Omit<TimeEntry, "id">
): Promise<TimeEntry> => {
  const { data, error } = await supabase
    .from("time_entries")
    .insert(timeEntry)
    .select()
    .single();

  if (error) {
    console.error("Error creating time entry:", error);
    throw error;
  }

  return data;
};

export const deleteTimeEntry = async (
  supabase: SupabaseClient,
  timeEntryId: string
): Promise<void> => {
  const { error } = await supabase
    .from("time_entries")
    .delete()
    .eq("id", timeEntryId);

  if (error) {
    console.error("Error deleting time entry:", error);
    throw error;
  }
};

export const getTimeEntriesForDateRange = async (
  supabase: SupabaseClient,
  userId: string,
  startDate: Date,
  endDate: Date
): Promise<(TimeEntry & { projects: { client_id: string } })[]> => {
  const { data, error } = await supabase
    .from("time_entries")
    .select("*, projects(client_id)")
    .gte("start_time", startDate.toISOString())
    .lte("start_time", endDate.toISOString())
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching time entries:", error);
    throw error;
  }

  return data;
};
