import { UserPreferences } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const fetchUserPreferences = async (
  supabase: SupabaseClient,
  userId: string
): Promise<UserPreferences | null> => {
  const { data, error } = await supabase
    .from("user_preferences")
    .select("*")
    .eq("user_id", userId)
    .single();

  if (error) {
    if (error.code !== "PGRST116")
      console.error("Error fetching preferences:", error);
    return null;
  }

  return data;
};

export const saveUserPreferences = async (
  supabase: SupabaseClient,
  preferences: UserPreferences
): Promise<UserPreferences> => {
  const { data, error } = await supabase
    .from("user_preferences")
    .insert(preferences)
    .select()
    .single();

  if (error) {
    console.error("Error saving preferences:", error);
    throw error;
  }

  return data;
};

export const createUserPreferences = async (
  supabase: SupabaseClient,
  userId: string,
  preferences: UserPreferences
): Promise<UserPreferences> => {
  const { data, error } = await supabase
    .from("user_preferences")
    .insert({ ...preferences, user_id: userId })
    .select()
    .single();

  if (error) {
    console.error("Error creating preferences:", error);
    throw error;
  }

  return data;
};
