import { useState } from "react";
import { usePaddle } from "./PaddleProvider";
import { Icons } from "../Icons";
import { LoadingSpinner } from "./LoadingSpinner";
import { useUser } from "@clerk/clerk-react";

interface TrialEndedDialogProps {
  isOpen: boolean;
}

export function TrialEndedDialog({ isOpen }: TrialEndedDialogProps) {
  const { user } = useUser();
  const [isAnnual, setIsAnnual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { paddle } = usePaddle();
  const appUrl = import.meta.env.VITE_APP_URL;
  let success = false;

  const monthlyPricing = Number(import.meta.env.VITE_PRICING_MONTHLY);
  const yearlyPricing = Number(import.meta.env.VITE_PRICING_YEARLY);

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const priceId = isAnnual
        ? import.meta.env.VITE_PADDLE_PRICE_ID_YEARLY
        : import.meta.env.VITE_PADDLE_PRICE_ID_MONTHLY;

      await paddle?.Checkout.open({
        items: [{ priceId, quantity: 1 }],
        settings: {
          successUrl: `${appUrl}/`, // TODO handle success AND failure -> paddle webhook neceessary to recheck subscription status in database after navigating back dashboard
          showAddTaxId: true,
        },
        customData: {
          userId: user?.id,
        },
      });
      console.log("Success:", success);
      success = true;
    } catch (error) {
      console.error("Checkout error:", error);
      success = false;
    } finally {
      setIsLoading(false);
      console.log("Finally Success:", success);
      //   onClose(); // Ensure the dialog closes after subscribing
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="modal modal-open">
        <div className="modal-box">
          <h3 className="font-bold text-lg text-center">Trial Ended</h3>
          <p className="py-4 text-center">
            Your free trial has ended. Upgrade to continue using Cronloom and
            unlock premium features!
          </p>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg pb-8 px-5 overflow-hidden">
            <div className="px-6 py-8">
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white text-center">
                Professional
              </h3>
              <div className="mt-4 flex items-center justify-center">
                <span
                  className={`text-sm ${
                    !isAnnual
                      ? "font-semibold text-gray-900 dark:text-white"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Monthly
                </span>
                <button
                  onClick={() => setIsAnnual(!isAnnual)}
                  className="relative inline-flex h-6 w-12 mx-3 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none bg-gray-200 dark:bg-gray-700"
                  role="switch"
                  aria-checked={isAnnual}
                >
                  <span
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      isAnnual ? "translate-x-6" : "translate-x-0"
                    }`}
                  />
                </button>
                <span
                  className={`text-sm ${
                    isAnnual
                      ? "font-semibold text-gray-900 dark:text-white"
                      : "text-gray-500 dark:text-gray-400"
                  }`}
                >
                  Annual
                </span>
              </div>

              <div className="mt-6 text-center">
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  ${isAnnual ? yearlyPricing : monthlyPricing}
                  <span className="text-lg text-gray-500 dark:text-gray-400">
                    /{isAnnual ? "year" : "month"}
                  </span>
                </p>
                {isAnnual && (
                  <p className="mt-2 text-sm text-center text-[#f161c0]">
                    Save ${(monthlyPricing * 12 - yearlyPricing).toFixed(2)}{" "}
                    annually (
                    {Math.round(
                      ((monthlyPricing * 12 - yearlyPricing) /
                        (monthlyPricing * 12)) *
                        100
                    )}
                    % off! 🎉)
                  </p>
                )}
              </div>
            </div>
            <ul className="mt-6 space-y-2">
              {[
                "Unlimited time tracking",
                "Project management",
                "Client management",
                "Custom hourly rates",
                "Advanced analytics dashboard",
                "CSV & Excel exports",
                "Priority email support",
                "Regular feature updates",
              ].map((feature, index) => (
                <li key={index} className="flex items-center">
                  <div className="flex-shrink-0 h-5 w-5 text-[#595eef]">
                    <Icons.Check />
                  </div>
                  <span className="ml-3">{feature}</span>
                </li>
              ))}
            </ul>
            <div className="mt-6 flex items-center">
              <input
                type="checkbox"
                id="terms"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                className="mr-2 checkbox checkbox-primary"
              />
              <label
                htmlFor="terms"
                className="text-sm text-gray-700 dark:text-gray-300"
              >
                I have read and agree to the{" "}
                <a
                  href="https://cronloom.io/terms-of-service"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Terms of Service
                </a>
                ,{" "}
                <a
                  href="https://cronloom.io/refund-policy"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Refund Policy
                </a>
                , and{" "}
                <a
                  href="https://cronloom.io/privacy-policy"
                  target="_blank"
                  className="text-blue-500 underline"
                >
                  Privacy Policy
                </a>{" "}
                of cronloom.io
              </label>
            </div>
          </div>

          <div className="modal-action flex justify-center">
            <button
              onClick={handleSubscribe}
              disabled={!isChecked || isLoading}
              className={`flex items-center justify-center space-x-2 bg-gradient-to-r from-[#f161c0] to-[#595eef] text-white px-6 py-3 rounded-lg hover:opacity-90 transition-opacity ${
                (!isChecked || isLoading) && "opacity-50 cursor-not-allowed"
              }`}
              style={{ backgroundSize: "110% 100%" }}
            >
              {isLoading ? <LoadingSpinner /> : <span>Upgrade Now</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
