import { Client } from "@/models";
import { SupabaseClient } from "@supabase/supabase-js";

export const fetchClients = async (
  supabase: SupabaseClient,
  userId: string
): Promise<Client[]> => {
  const { data, error } = await supabase
    .from("clients")
    .select("*")
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }

  return data || [];
};
