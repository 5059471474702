import logo from "/src/assets/logo.svg";

export function Logo() {
  return (
    <a href="/" className="flex items-center space-x-2">
      <img src={logo} alt="cronloom Logo" className="h-8 w-8" />
      <span className="ml-2 text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#f161c0] to-[#595eef]">
        cronloom.io
      </span>
    </a>
  );
}
